import classnames from 'classnames';
import { useCallback, type FunctionComponent, type MouseEvent } from 'react';

import { ArrowLeft } from '@/core/tamagoshi/icons/ArrowLeft';
import { ArrowRight } from '@/core/tamagoshi/icons/ArrowRight';

import styles from './Control.module.scss';

export type Direction = 'right' | 'left';

export interface DirectionOptions {
  scrollBy?: number | { (): number };
}

export interface ControlProps {
  ariaLabel?: string;
  arrowsColorClassName?: string;
  arrowsDisableColorClassName?: string;
  className?: string;
  direction: Direction;
  disable?: boolean;
  onClick(direction: Direction): void;
}

export const Control: FunctionComponent<ControlProps> = ({
  disable = false,
  onClick,
  direction,
  arrowsColorClassName,
  arrowsDisableColorClassName,
  className,
  ariaLabel,
  ...forwardedProps
}) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onClick(direction);
    },
    [direction, onClick],
  );

  const rootClassnames = classnames(
    styles.root,
    arrowsColorClassName,
    {
      [styles.disabled]: disable,
    },
    disable && arrowsDisableColorClassName,
    className,
  );

  return (
    <button
      aria-disabled={disable}
      aria-label={ariaLabel || `Slide ${direction}`}
      className={rootClassnames}
      disabled={disable}
      onClick={handleClick}
      type="button"
      {...forwardedProps}
    >
      {direction === 'right' ? (
        <ArrowRight className={styles.icon} />
      ) : (
        <ArrowLeft className={styles.icon} />
      )}
    </button>
  );
};

Control.displayName = 'Control';
