import { type RefObject } from 'react';

/**
 * Get element children with width > 0
 */
export function getVisibleChildren(
  slider: RefObject<HTMLDivElement>,
): HTMLElement[] | null {
  if (!(slider && slider.current && slider.current.children.length !== 0)) {
    return null;
  }

  return Array.from(slider.current.children).filter(
    (elt) =>
      !(elt as HTMLElement).getAttribute('data-test-width') ||
      (elt as HTMLElement).getBoundingClientRect().width > 0,
  ) as HTMLElement[];
}

/**
 * Get offset from the first element's offset + its margin-left
 */
export function getScrollOffset(slider: RefObject<HTMLDivElement>): number {
  const childrenArray = getVisibleChildren(slider);

  return childrenArray && childrenArray.length > 0
    ? (childrenArray[0] as HTMLElement).offsetLeft +
        parseInt(
          window.getComputedStyle(childrenArray[0] as HTMLElement).marginLeft,
          10,
        )
    : 0;
}

/**
 * Get scroll value
 */
export function getScroll(slider: RefObject<HTMLDivElement>): number {
  return slider && slider.current ? slider.current.scrollLeft : 0;
}

/**
 * Get scroll max value
 */
export function getScrollMax(slider: RefObject<HTMLDivElement>): number {
  return slider && slider.current
    ? slider.current.scrollWidth - slider.current.clientWidth
    : 0;
}

/**
 * Get scroll value for a given child element
 */
export function getScrollTargetForSlide(
  slide: HTMLElement,
  slider: RefObject<HTMLDivElement>,
) {
  return slide?.offsetLeft - getScrollOffset(slider);
}

/**
 * Get distance from slide to scroll position
 */
export function distanceToPosition(
  slide: HTMLElement,
  position: number,
  slider: RefObject<HTMLDivElement>,
) {
  return Math.abs(getScrollTargetForSlide(slide, slider) - position);
}
