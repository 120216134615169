import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const ArrowRight: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemArrowRight" viewBox="0 0 24 24" {...props}>
    <path
      d="M9.293 15.463l3.88-3.88-3.88-3.88a.996.996 0 111.41-1.41l4.59 4.59c.39.39.39 1.02 0 1.41l-4.59 4.59a.996.996 0 01-1.41 0c-.38-.39-.39-1.03 0-1.42z"
      fill="currentColor"
    />
  </Svg>
);

ArrowRight.displayName = 'ArrowRight';
