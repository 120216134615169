import classnames from 'classnames';
import type React from 'react';
import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ComponentType,
  type PropsWithChildren,
  type ReactElement,
} from 'react';

import {
  Control,
  type Direction,
  type DirectionOptions,
} from './components/Control/Control';
import { Slider, type SliderHandle } from './components/Slider/Slider';
import { Title } from './components/Title/Title';

import styles from './Carousel.module.scss';

export interface CarouselProps extends PropsWithChildren {
  /** Pass an id to the carousel to allow the component to be selected by id
   */
  id?: string;
  /**
   * Carousel title, can be component, element or string.
   * Rather use component for custom title css, media queries, etc.
   */
  title?:
    | string
    | ReactElement<{ className: string }>
    | ComponentType<React.PropsWithChildren<{ className: string }>>;
  /**
   * Hide control arrows
   */
  noControls?: boolean;
  /**
   * Root element className
   */
  className?: string;
  /**
   * Title element className
   */
  titleClassName?: string;
  /**
   * Controls element className
   */
  controlsClassName?: string;
  /**
   * Arrows element className used to pass the color
   */
  arrowsColorClassName?: string;
  /**
   * Arrows element className used to pass the disabled arrows color
   */
  arrowsDisabledColorClassName?: string;
  /**
   * Slider element className
   */
  sliderClassName?: string;
  /**
   * Slider element options
   */
  sliderDirectionOptions?: DirectionOptions;
  /**
   * If your elements has horizontal box-shadow, you need to add multiple
   * spacings to keep it. If you specify a size in pixel with this props, we did
   * that for you 😉
   */
  horizontalPadding?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * For accessibility purpose, please provide a aria-label on Controls Arrow Left
   */
  ariaLabelLeft?: string;
  /**
   * For accessibility purpose, please provide a aria-label on Controls Arrow Right
   */
  ariaLabelRight?: string;
  /**
   * Data-testid prefix for Carousel and its arrows
   */
  dataTestId?: string;
  /**
   * Scroll the carousel to the element at that position (0 based).
   */
  initialScrollIndex?: number;
  /**
   * Used to intercept slides change either by navigating or by scrolling
   * This is not triggered by the initialScrollIndex
   */
  onSlideChange?: (currentPosition: number, previousPosition: number) => void;
  /**
   * Event to know if user click on control to navigate
   * @param direction @type {Direction}
   */
  onControlClick?(direction: Direction): void;
  /**
   * When component receives an array as a children, it will render the same number of elements as this prop value
   * 50 as default
   */
  maxItemsToRender?: number;
  /**
   * If true, the carousel will have the arrows at the left/right sides
   */
  withSideArrows?: boolean;
  trackArrowClick?: (direction: Direction) => void;
}

export const Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  (
    {
      ariaLabelLeft,
      ariaLabelRight,
      children,
      className,
      controlsClassName,
      dataTestId,
      horizontalPadding = '',
      id,
      initialScrollIndex = 0,
      maxItemsToRender,
      noControls = false,
      onSlideChange,
      onControlClick,
      sliderClassName,
      sliderDirectionOptions,
      title,
      titleClassName,
      arrowsColorClassName,
      arrowsDisabledColorClassName,
      withSideArrows = false,
      trackArrowClick,
    },
    ref,
  ) => {
    /**
     * The max value is the max scrollLeft position, which will be calculated by
     * the slider when it will mount. But the initial value is set to "1"
     * (instead of 0), so the right arrow is "enabled" at load time, and so
     * avoiding a blink at startup.
     */
    const [scrollState, setScrollState] = useState({
      index: 0,
      position: 0,
      min: 0,
      max: 1,
    });

    const slider = useRef<SliderHandle>(null);
    const previousIndexRef = useRef(0);
    const waitForInitialScrollRef = useRef(!!initialScrollIndex);

    const handleControlClick = (direction: Direction) => {
      slider.current?.scrollTo(direction, sliderDirectionOptions);
      onControlClick?.(direction);
      if (trackArrowClick) {
        trackArrowClick(direction);
      }
    };

    useEffect(() => {
      if (initialScrollIndex > 0 && slider && slider.current) {
        slider.current.scrollToIndex(initialScrollIndex, false);
      }
    }, [initialScrollIndex]);

    const rootClassnames = classnames(styles.root, className, {
      [styles.withSideArrowsRoot]: withSideArrows && !noControls,
      [styles[`horizontal-${horizontalPadding}`]]: !!horizontalPadding,
    });

    const leftScrollDisabled = scrollState.position <= scrollState.min;
    const rightScrollDisabled = scrollState.position >= scrollState.max;

    const titleClassnames = classnames(styles.title, titleClassName, {
      [styles.withSideArrowsTitle]: withSideArrows && !noControls,
    });

    const sliderClassnames = classnames(styles.slider, sliderClassName);

    const controlsClassnames = classnames(styles.controls, controlsClassName);

    useEffect(() => {
      if (
        onSlideChange &&
        scrollState.index !== previousIndexRef.current &&
        !waitForInitialScrollRef.current
      ) {
        onSlideChange(scrollState.index, previousIndexRef.current);
      }

      if (
        waitForInitialScrollRef.current &&
        scrollState.index === initialScrollIndex
      ) {
        waitForInitialScrollRef.current = false;
      }

      previousIndexRef.current = scrollState.index;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollState.index]);

    const itemsToRender = useMemo(
      () =>
        maxItemsToRender && maxItemsToRender > 0 && Array.isArray(children)
          ? children.slice(0, maxItemsToRender)
          : children,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [children],
    );

    if (withSideArrows && !noControls) {
      return (
        <div
          id={id}
          className={rootClassnames}
          data-testid={dataTestId}
          ref={ref}
        >
          <div className={styles.withSideArrowsTitle}>
            {title && <Title value={title} className={titleClassnames} />}
          </div>

          <div className={classnames(controlsClassnames, styles.controlLeft)}>
            <Control
              className={classnames(
                styles.withSideArrowsButton,
                !leftScrollDisabled && styles.withSideArrowsHover,
              )}
              direction="left"
              disable={leftScrollDisabled}
              onClick={handleControlClick}
              ariaLabel={ariaLabelLeft}
              data-testid={dataTestId && `left-arrow-${dataTestId}`}
              arrowsColorClassName={arrowsColorClassName}
              arrowsDisableColorClassName={arrowsDisabledColorClassName}
            />
          </div>
          <Slider
            ref={slider}
            className={sliderClassnames}
            onScrollChange={setScrollState}
          >
            {itemsToRender}
          </Slider>

          <div className={classnames(controlsClassnames, styles.controlRight)}>
            <Control
              className={classnames(
                styles.withSideArrowsButton,
                !rightScrollDisabled && styles.withSideArrowsHover,
              )}
              direction="right"
              disable={rightScrollDisabled}
              onClick={handleControlClick}
              ariaLabel={ariaLabelRight}
              data-testid={dataTestId && `right-arrow-${dataTestId}`}
              arrowsColorClassName={arrowsColorClassName}
              arrowsDisableColorClassName={arrowsDisabledColorClassName}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        id={id}
        className={rootClassnames}
        data-testid={dataTestId}
        ref={ref}
      >
        {title && <Title value={title} className={titleClassnames} />}

        {!noControls && (
          <div className={controlsClassnames}>
            <Control
              direction="left"
              disable={leftScrollDisabled}
              onClick={handleControlClick}
              ariaLabel={ariaLabelLeft}
              data-testid={dataTestId && `left-arrow-${dataTestId}`}
              arrowsColorClassName={arrowsColorClassName}
              arrowsDisableColorClassName={arrowsDisabledColorClassName}
            />
            <Control
              direction="right"
              disable={rightScrollDisabled}
              onClick={handleControlClick}
              ariaLabel={ariaLabelRight}
              data-testid={dataTestId && `right-arrow-${dataTestId}`}
              arrowsColorClassName={arrowsColorClassName}
              arrowsDisableColorClassName={arrowsDisabledColorClassName}
            />
          </div>
        )}
        <Slider
          ref={slider}
          className={sliderClassnames}
          onScrollChange={setScrollState}
        >
          {itemsToRender}
        </Slider>
      </div>
    );
  },
);

Carousel.displayName = 'Carousel';
