import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const ArrowLeft: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemArrowLeft" viewBox="0 0 24 24" {...props}>
    <path
      d="M14.71 15.88L10.83 12l3.88-3.88a.996.996 0 10-1.41-1.41L8.71 11.3a.996.996 0 000 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z"
      fill="currentColor"
    />
  </Svg>
);

ArrowLeft.displayName = 'ArrowLeft';
