import classnames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  type PropsWithChildren,
} from 'react';

import { type Direction, type DirectionOptions } from '../Control/Control';
import {
  distanceToPosition,
  getScroll,
  getScrollMax,
  getScrollTargetForSlide,
  getVisibleChildren,
} from './sliderUtils';

import styles from './Slider.module.scss';

const SCROLL_MIN = 0;

export type { Direction };

export interface ScrollState {
  index: number;
  position: number;
  min: number;
  max: number;
}

export interface SliderProps extends PropsWithChildren {
  className?: string;
  onScrollChange?(state: ScrollState): void;
  disableScrollSnap?: boolean;
}

export interface SlideDistance {
  slide: HTMLElement;
  distance: number;
  index: number;
}

export interface SliderHandle {
  scrollTo(direction: Direction, options?: DirectionOptions): void;
  scrollToIndex(index: number, smooth?: boolean): void;
  scrollToPosition(position: number, smooth?: boolean): void;
}

export const Slider = forwardRef<SliderHandle, SliderProps>(
  (
    {
      className,
      onScrollChange = () => null,
      disableScrollSnap = false,
      children,
    },
    forwardedRef,
  ) => {
    const slider = useRef<HTMLDivElement>(null);

    let lastClosestSlideIndex = 0;

    /**
     * Get closest slide
     *
     * @param fromNavigation Allow us to know when it is called from clicking on the controls
     * @param direction Give the navigation direction (left | right)
     */
    const getClosestSlide = (
      fromNavigation = false,
      direction?: Direction,
    ): null | SlideDistance => {
      const childrenArray = getVisibleChildren(slider);

      if (!childrenArray) {
        return null;
      }

      const position = getScroll(slider);

      let currentIndex = lastClosestSlideIndex;
      let currentDistance = distanceToPosition(
        childrenArray[lastClosestSlideIndex],
        position,
        slider,
      );

      // force navigation to first card when we are too close to it
      if (
        currentIndex === 0 &&
        position > 0 &&
        fromNavigation &&
        direction === 'left'
      ) {
        return {
          slide: childrenArray[1],
          index: 1,
          distance: 0,
        };
      }

      let searchDirection =
        currentIndex >= childrenArray.length - 1 || direction === 'left'
          ? -1
          : 1;
      let shouldTestOtherDirection = true;

      while (searchDirection !== 0) {
        const candidateIndex = currentIndex + searchDirection;

        if (candidateIndex >= 0 && candidateIndex < childrenArray.length) {
          const candidateSlide = childrenArray[candidateIndex];
          const candidateDistance = distanceToPosition(
            candidateSlide,
            position,
            slider,
          );

          if (candidateDistance < currentDistance) {
            shouldTestOtherDirection = false;
            currentDistance = candidateDistance;
            currentIndex = candidateIndex;
          } else if (fromNavigation && candidateDistance === currentDistance) {
            shouldTestOtherDirection = false;
            currentIndex = candidateIndex;
          } else if (fromNavigation) {
            currentDistance = candidateDistance;
            searchDirection = 0;
          } else if (shouldTestOtherDirection) {
            searchDirection *= -1;
            shouldTestOtherDirection = false;
          } else {
            searchDirection = 0;
          }
        } else if (shouldTestOtherDirection) {
          searchDirection *= -1;
          shouldTestOtherDirection = false;
        } else {
          searchDirection = 0;
        }
      }

      lastClosestSlideIndex = currentIndex;

      return {
        slide: childrenArray[currentIndex],
        index: currentIndex,
        distance: currentDistance,
      };
    };

    const scrollToElement = (element: HTMLElement, smooth = true) =>
      slider?.current?.scrollTo({
        left: getScrollTargetForSlide(element, slider),
        behavior: smooth ? 'smooth' : 'auto',
      });

    const updateScrollState = () => {
      const closestSlide = getClosestSlide();
      onScrollChange({
        index: closestSlide?.index || 0,
        position: getScroll(slider),
        min: SCROLL_MIN,
        max: getScrollMax(slider),
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onScroll = useCallback(updateScrollState, []);

    /**
     * listen to scroll event
     */
    useEffect(() => {
      let timer: number;
      const debouncedOnScroll = () => {
        window.clearTimeout(timer);
        timer = window.setTimeout(onScroll, 16);
      };

      const currentSlider = slider && slider.current;
      if (currentSlider) {
        currentSlider.addEventListener('scroll', debouncedOnScroll);
        currentSlider.scrollLeft = 0;
      }

      return () => {
        if (currentSlider) {
          currentSlider.removeEventListener('scroll', debouncedOnScroll);
        }
      };
    }, [slider, onScroll]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => updateScrollState(), [children]);

    useImperativeHandle(
      forwardedRef,
      () => ({
        scrollTo(direction: Direction, options = {}): void {
          const { scrollBy } = { scrollBy: 1, ...options };
          const scrollByValue =
            typeof scrollBy === 'function' ? scrollBy() : scrollBy;

          const closestSlide = getClosestSlide(true, direction);
          if (closestSlide && slider && slider.current) {
            if (direction === 'left' && closestSlide.index > 0) {
              scrollToElement(
                slider.current.children[
                  Math.max(0, closestSlide.index - scrollByValue)
                ] as HTMLElement,
              );
            }
            if (
              direction === 'right' &&
              closestSlide.index < slider.current.childElementCount - 1
            ) {
              scrollToElement(
                slider.current.children[
                  Math.min(
                    slider.current.children.length - 1,
                    closestSlide.index + scrollByValue,
                  )
                ] as HTMLElement,
              );
            }
          }
        },
        scrollToIndex(index: number, smooth = true) {
          const childrenArray = getVisibleChildren(slider);

          if (childrenArray && index >= 0 && index < childrenArray.length) {
            scrollToElement(childrenArray[index], smooth);
          }
        },
        scrollToPosition(position: number, smooth = true) {
          return slider?.current?.scrollTo({
            left: position,
            behavior: smooth ? 'smooth' : 'auto',
          });
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [slider],
    );

    const rootClassnames = classnames(styles.root, className, {
      [styles.noScrollSnap]: disableScrollSnap,
    });

    return (
      <div className={rootClassnames} ref={slider}>
        {children}
      </div>
    );
  },
);

Slider.displayName = 'Slider';
