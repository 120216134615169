import {
  cloneElement,
  type ComponentType,
  type FunctionComponent,
  type ReactElement,
} from 'react';

import { Text } from '@/core/tamagoshi/Text/Text';

interface Props {
  value:
    | string
    | ReactElement<{ className: string }>
    | ComponentType<{ className: string }>;
  className?: string;
}

export const Title: FunctionComponent<Props> = ({ value, className }) => {
  if (typeof value === 'string') {
    return (
      <Text type="body1" weight="bold" className={className}>
        {value}
      </Text>
    );
  }

  if ((value as ReactElement).props) {
    const titleElement = value as ReactElement;
    return cloneElement(titleElement, { className });
  }
  const ImageComponent = value as ComponentType;
  const titleElement = <ImageComponent />;
  return cloneElement(titleElement, { className });
};
